import React from "react"
import PageRoot from "../components/PageRoot"
import { useTranslation } from "react-i18next"
import { Col, Row } from "antd"
import ContainerWithPadding from "../components/ContainerWithPadding"
import "../style/coming-soon.less"

const ComingSoonPage = () => {
  const { t } = useTranslation()

  const rootLayoutRowSettings = {
    justify: "space-around",
  }

  const rootLayoutColSettings = {
    xs: 24,
  }

  return (
    <PageRoot title={"coming-soon"} layout={"coming-soon"}>
      <div className="coming-soon-page">
        <ContainerWithPadding size={"small"} align={"center"}>
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <img
                src="/assets/images/coming-soon/side-logo.svg"
                alt="logo"
                className={"logo"}
              />
            </Col>
            <Col {...rootLayoutColSettings}>
              <img
                src="/assets/images/coming-soon/Next.svg"
                alt="next"
                className={"logo next"}
              />
            </Col>
            <Col {...rootLayoutColSettings}>
              <h2 className={"title"}>
                {t("comingSoon:titlePresentation1")} <br />
                {t("comingSoon:titlePresentation2")}
              </h2>
            </Col>
          </Row>
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export default ComingSoonPage
